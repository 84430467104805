.white{
    color: white;
}
.description-container {
    word-wrap: break-word;
  }

  .error-size {
    font-size: 9.5rem;
    color: #f58220;
  }